import React from "react"
import Button from "../../Elements/Button"
import Tag from "../../Elements/Tag"
import Tags from "../../Elements/Tags"
import { Table, Tr, Td } from "../TableElements"
import ButtonEdit from "../../Elements/ButtonEdit"
import ButtonDelete from "../../Elements/ButtonDelete"
const toCapitalCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export default function ProductsTable({ data, onEditClick, onDeleteClick }) {
  return (
    <Table columns={["ID", "Title", "Brand", "Product Type", "Actions"]}>
      {data.map((file, k) => (
        <Tr key={k}>
          <Td>{file.id}</Td>
          <Td>{file.title}</Td>
          <Td>{file?.field_product_brand[0]?.title}</Td>
          {/*<Td>
            <Tags>
              {file.field_product_categories.map(v => (
                <Tag>{v.title}</Tag>
              ))}
            </Tags>
          </Td>*/}
          <Td>
            {file.field_product_categories.map((v, k) => (
              <>
                {v.title}
                {k !== file.field_product_categories.length - 1 && ", "}
              </>
            ))}
          </Td>
          <Td className="px-6 py-4 whitespace-nowrap  text-sm font-medium flex justify-between">
            <div className="flex space-x-6">
              <ButtonEdit text="Edit" onClick={() => onEditClick(file.id)} />
              <ButtonDelete
                text="Delete"
                onClick={() => onDeleteClick(file.id)}
              />
            </div>

            {/*  <button
              className="text-indigo-600 hover:text-indigo-900"
              onClick={() => onEditClick(file.id)}
            >
              Edit
            </button>
            <button
              onClick={() => {}}
              className="text-indigo-600 hover:text-red-600"
            >
              Delete
            </button> */}
          </Td>
        </Tr>
      ))}
    </Table>
  )
}
